import U939底座检测报告 from "./images/U939底座 检测报告.jpg"
import U939主机检测报告 from "./images/U939主机 检测报告.jpg"
import U929检测报告 from "./images/U929 检测报告.jpg"
import U929嵌入式软著 from "./images/U929嵌入式 软著.jpg"
import 称重实用新型专利 from "./images/称重 实用新型专利.jpg"
import 称重器外观专利 from "./images/称重器 外观专利.jpg"
import 防爆软著 from "./images/防爆 软著.jpg"
import 优气宝小程序软著 from "./images/优气宝小程序 软著.jpg"
import 优气达APP软著 from "./images/优气达APP 软著.jpg"
import 优气物联软著 from "./images/优气物联 软著.jpg"
export default [
  {name: "U939底座 检测报告", logo: U939底座检测报告},
  {name: "U939主机 检测报告", logo: U939主机检测报告},
  {name: "U929 检测报告", logo: U929检测报告},
  {name: "U929嵌入式 软著", logo: U929嵌入式软著},
  {name: "称重 实用新型专利", logo: 称重实用新型专利},
  {name: "称重器 外观专利", logo: 称重器外观专利},
  {name: "防爆 软著", logo: 防爆软著},
  {name: "优气宝小程序 软著", logo: 优气宝小程序软著},
  {name: "优气达APP 软著", logo: 优气达APP软著},
  {name: "优气物联 软著", logo: 优气物联软著},
]