<template>
  <div class="about">
    <headerImg :src="img0" />
    <div class="content" style="padding: 30px 0 10px 0">
      <div class="about-nav">
        <div class="about-nav-item" :class="typeLists[index] == type && 'about-nav-item-act'" v-for="(item, index) in typeList" :key="index" @click="$router.push('/about/'+typeLists[index])">{{item}}</div>
      </div>
      <h2 class="select-nav" v-if="type != 'join'">{{typeList[type]}}</h2>
    </div>
    <div class="content" v-if="type == 'enterpriseIntroduction'">
      <div class="company">
        <div class="company-left">
          <div>深圳多知能源物联有限公司</div>
          <div>Shenzhen Duozhi Technology Co., Ltd</div>
          <div>2018年10月30日，深圳多知能源物联有限公司在深圳福田区成立，获得中国500强 -“华强集团”旗下电子产业互联网一站式服务平台 -“深圳华秋电子有限公司”的战略投资，致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。

多知专注于LPG物联网应用、终端业务管理以及配套软硬件产品的研发，并获得多项软著、实用新型和发明专利。

2020年9月，经过两年的积累与沉淀，多知推出优气宝物联网解决方案，包含智能硬件采集模块、燃企运营系统、燃企配送移动端、气瓶用户移动端、政府监管系统五大模块，打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。</div>
        </div>
        <div class="company-right">
          <img title="深圳多知能源物联有限公司" src="@/assets/images/about/1.png" alt="">
        </div>

        <div class="company-partner">
          <strong>合作机构</strong>
          <span>PARTNER</span>
          <div class="des">深圳多知能源物联有限公司携手全球合作伙伴，共创未来</div>
          <div class="partner-logo">
            <img :src="item" alt="" v-for="(item, index) in partnerList" :key="index">
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-if="type == 'honoraryQualifications'">
      <div style="text-align: left;font-size: 14px;">唯有匠人之心 方能有所成就</div>
      <div class="qualifications">
        <div :title="item.name" v-for="(item, index) in qualifications" :key="index">
          <img :src="item.logo" :alt="item.name">
          <div>{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="content" v-if="type == 'qualityAssurance'">
      <div style="text-align: left;font-size: 14px;">引进全球最先进的检测设备，遵循出口欧美的产品生产制造标准。</div>
      <img style="width: 100%;margin-top: 60px" src="@/assets/images/about/10.jpg" alt="">
    </div>
    <div class="content" v-if="type == 'companyVideo'">
      <div style="text-align: left;font-size: 14px;">关于多知科技出品，以下视频将帮助您更好地了解。</div>
      <div class="video-box">
        <div class="video-type">
          <div>企业视频</div>
        </div>
        <div class="video-list">
          <a :href="'/about/video/?id='+index" v-for="(item, index) in videoList" :title="item.title" :key="index">
            <img :src="item.img" alt="">
            <div class="iconfont duozhi-icon-bofang"></div>
            <div>{{item.title}}</div>
          </a>
        </div>
      </div>
    </div>
    <div v-if="type == 'join'">
      <div class="content-title">多聚英才 知取未来</div>
      <div class="content-sub-title">深圳多知能源物联有限公司是一家致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务的科技企业</div>
      <div class="content-sub-title">旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验</div>
      <div class="content-sub-title">如果你志存高远，怀揣梦想，那就加入深圳多知能源物联有限公司吧</div>
      <div class="content-sub-title">我们希望与你一起，知取未来</div>
      <headerImg style="margin-top: 120px;" title="多聚英才 知取未来" :src="img14" />
      <div class="content-title">精工制造 精品服务</div>
      <div class="content-sub-title">坚持对每一款产品都要精益求精、精雕细琢</div>
      <div class="content-sub-title">完整的智能产线配套，建立完整的质量监控体系，责任到每个岗位，从源头抓品质，不放过每一个细节</div>
      <headerImg style="margin-top: 120px;" title="精工制造 精品服务" :src="img15" />
      <div class="content-title">加入多知<br /> 欢迎你的到来</div>
      <a href="https://www.zhipin.com/gongsir/87a76897fb2df17b1HN82tm0Ew~~.html?ka=company-jobs" target="_blank" class="content-button">查看工作岗位</a>
    </div>

  </div>
</template>

<script>
import headerImg from "@/components/headerImg.vue"
import img0 from "@/assets/images/about/0.png"
import img14 from "@/assets/images/about/14.png"
import img15 from "@/assets/images/about/15.png"
import qualifications from "@/assets/hypocritical/qualifications.js"
import videoList from "@/assets/hypocritical/videoList.js"


// 合作伙伴
import zgyd from "@/assets/images/about/partner/zgyd.png"
import zgdx from "@/assets/images/about/partner/zgdx.png"
import zglt from "@/assets/images/about/partner/zglt.png"
import aly from "@/assets/images/about/partner/aly.png"
import lx from "@/assets/images/about/partner/lx.png"
import wr from "@/assets/images/about/partner/wr.png"
import szyc from "@/assets/images/about/partner/szyc.png"
import zoomcar from "@/assets/images/about/partner/zoomcar.png"
import bd from "@/assets/images/about/partner/bd.png"

export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content: "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerImg},
  data(){
    return {
      img0,
      img14,
      img15,
      typeList: [
        "企业介绍",
        "荣誉资质",
        "品质保障",
        "多知视频",
        "加入多知",
      ],
      typeLists: [
        "enterpriseIntroduction",
        "honoraryQualifications",
        "qualityAssurance",
        "companyVideo",
        "join",
      ],
      qualifications,
      videoList,
      partnerList: [zgyd, zgdx, zglt, aly, lx, wr, szyc, zoomcar, bd ]
    }
  },
  computed: {
    type(){
      return this.$route.params.type
    }
  },
  watch: {
    type(){
      
    }
  }

}
</script>

<style lang="scss" scoped>
.about{
  .content{
    max-width: 1600px;
    .about-nav{
      width: 98%;
      padding: 0 1%;
      border-bottom: 1px solid #e0dede;
      display: flex;
      justify-content: center;
      .about-nav-item{
        line-height: 50px;
        width: 120px;
        max-width: 20%;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        transition: all .2s;
        &::before{
          transition: all .2s;
          content: "";
          position: absolute;
          left: 0px;
          bottom: 0px;
          background: #2da0db;
          height: 1px;
          width: 0;
        }
      }
      .about-nav-item:hover, .about-nav-item-act{
        color: #2da0db;
        &::before{
          content: "";
          position: absolute;
          left: 0px;
          bottom: 0px;
          background: #2da0db;
          height: 2px;
          width: 100%;
        }
      }
    }
    .select-nav{
      text-align: left;
      position: relative;
      margin-top: 40px;
      font-size: 18px;
      font-weight: normal;
      &::before{
        content: "";
        position: absolute;
        top: -10px;
        left: 0;
        width: 72px;
        height: 5px;
        background-color: #2da0db;
      }
    }
  }
}
.company{
  display: flex;
  flex-wrap: wrap;
  &-left{
    width: 60%;
    text-align: left;
    & > div:nth-child(1){
      font-size: 25px;
      line-height: 36px;
      color: #848484;
      font-weight: 600;
    }
    & > div:nth-child(2){
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 40px;
      color: #848484;
      position: relative;
      font-weight: 600;
      &::before{
        content: "";
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 72px;
        height: 5px;
        background-color: #2da0db;
      }
    }
    & > div:nth-child(3){
      font-size: 15px;
      color: #b6b9ba;
      text-indent:2em;
    }
  }
  &-right{
    width: 40%;
    img{
      width: 100%;
    }
  }
  &-partner{
    width: 100%;
    position: relative;
    margin-top: 30px;
    & > span{
      width: 100%;
      position: absolute;
      left: 0;
      top: -40px;
      color: #F3F7F9;
      font-size: 86px;
      line-height: 120px;
      text-transform: uppercase;
      font-weight: 500;
      z-index: 1;
    }
    & > strong{
      display: block;
      font-size: 32px;
      position: relative;
      z-index: 2;
      font-weight: 600;
      line-height: 52px;
      color: #000000;
      &::after{
        display: block;
        content: '';
        width: 80px;
        height: 2px;
        margin: 10px auto 0;
        background: linear-gradient(135deg,rgba(0,0,255,1) 0%,rgba(155,250,255,1) 100%);
      }
    }
    .des{
      font-weight: 400;
      margin: 18px auto 0;
      color: #000E48;
      max-width: 850px;
    }
    .partner-logo{
      display: flex;
      flex-wrap: wrap;
      & > img{
        width: calc(100% / 7);
        cursor: pointer;
        transition: all 0.4s
      }
      & > img:hover{
        transform: scale(1.1)
      }
    }
  }
}
.qualifications{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  & > div{
    width: 23%;
    margin: 0 1%;
    margin-top: 20px;
    & > img{
      width: 80%;
    }
    & > div{
      background: #f0f0f2;
      padding: 34px 0;
      font-size: 16px;
      margin-top: -30px;
      transition: all .3s;
    }
  }
  & > div:hover{
    & > div{
      background: #2da0db;
      color: white;
    }

  }
}
.video-box{
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 100px;
  .video-type{
    min-width: 160px;
    line-height: 60px;
    height: 60px;
    background: #2da0db;
    margin-right: 10vw;
    cursor: pointer;
    & > div{
      color: white;
    }
  }
  .video-list{
    flex: 1;
    display: flex;
    justify-content: space-between;
    & > a{
      width: 32%;
      background: #edf0f2;
      display: flex;
      flex-direction: column;
      line-height: 55px;
      cursor: pointer;
      position: relative;
      & > img{
        width: 100%;
        height: 13vw;
      }
      & > .duozhi-icon-bofang{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 13vw;
        color: white;
        font-size: 40px;
        line-height: 170px;
        background: #00000059;
        opacity: 0;
        transition: all .2s;
      }
    }
    & > div:hover{
      background: #2da0db;
      div{
        color: white;
      }
      & > .duozhi-icon-bofang{
        opacity: 1;

      }
      
    }
  }
}
.content-title{
  font-size: 28px;
  margin-top: 120px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #666666;
}
.content-sub-title{
  color: #666666;
  line-height: 30px;
  font-size: 15px;
}
.content-button{
  display: block;
  margin: 50px auto 120px auto;
  width: 180px;
  height: 45px;
  line-height: 45px;
  background: #2da0db;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .company{
    .company-left, .company-right{
      width: 100%!important;
    }
    .company-partner{
      .des{
        font-size: 14px;
      }
      .partner-logo{
        img{
          width: 50%;
        }
      }
    }
  }
  .qualifications{
    & > div{
      width: 98%;
    }
  }
  .video-box{
    flex-direction: column;
    .video-type{
      width: 100%;
    }
    .video-list{
      flex-direction: column;
      margin-top: 10px;
      & > a{
        width: 100%;
        & > img{
          height: 50vw;
        }
      }
    }
  }
  .content-title{
    font-size: 24px!important;
  }
  .content-sub-title{
    font-size: 14px!important;
  }
}
</style>